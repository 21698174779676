import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { PagesIndex } from "../../../PagesIndex";
import "./history.css";
import { Connection, PublicKey, clusterApiUrl } from '@solana/web3.js';
import { Program, AnchorProvider } from '@project-serum/anchor';
import { useWallet } from '@solana/wallet-adapter-react';
import idl from '../../../../idl/newwiffinaltest.json';

const PrivateSale = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [copySuccess, setCopySuccess] = useState("");
  const [selectRound, setSelectRound] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [buyingHistory, setBuyingHistory] = useState([]);
  const [roundData,setRoundData] = useState([]);

  const handleCopy = (index) => {
    setCopySuccess(index);
    setTimeout(() => {
      setCopySuccess("");
    }, 1000);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.trim().toLowerCase());
    setPage(0);
  };

  const handleSelectRound = (e) => {
    setSelectRound(e.target.value);
    console.log(e.target.value,"555");
    setPage(0);
  };

  const wallet = useWallet();
  const connection = new Connection(clusterApiUrl('devnet'));
  const provider = new AnchorProvider(connection, wallet, AnchorProvider.defaultOptions());
  const programID = new PublicKey(idl.metadata.address);
  const program = new Program(idl, programID, provider);

  const fetchBuyingHistory = async () => {
    try {
      const accounts = await program.account.vesting.all();
      const history = accounts
        .map((account) => {
          return account.account.purchases.map((purchase, purchaseIndex) => ({
            index: purchaseIndex + 1,
            date: new Date(purchase.startTime.toNumber() * 1000),
            address: account.account.owner.toBase58(),
            round: purchase.round,
            amount: purchase.amount.toString()
          }));
        })
        .flat()?.sort((a, b) => b?.date - a?.date);
      setBuyingHistory(history);
      const initialRoundData = await accounts?.map((_, index) => index + 1);
      setRoundData(initialRoundData);
    } catch (error) {
      console.error('Failed to fetch buying history:', error);
    }
  };

  useEffect(() => {
    fetchBuyingHistory();
  }, []);

  useEffect(() => {
    const filteredData = buyingHistory
      .filter((item) => selectRound === "All" || item.round + 1 === parseInt(selectRound))
      .filter((item) => {
        const formattedDate = item.date
          ? new Date(item.date).toLocaleString("en-US", {
              day: "2-digit",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }).toLowerCase()
          : "";
        const amountString = item.amount ? (item.amount / 10**6).toString() : "";
        return (
          (item.address && item.address.toLowerCase().includes(searchTerm)) ||
          formattedDate.includes(searchTerm) ||
          amountString.includes(searchTerm)
        );
      });
    setFilterData(filteredData);
    setPage(0);
  }, [selectRound, searchTerm, buyingHistory]);

  const abbreviateAddress = (address) => {
    return `${address.slice(0, 8)}.....${address.slice(-5)}`;
  };

  return (
    <>
      <Index.Box className="presale-wraper">
        <Index.Box className="pre-history-heading-box">
          <Index.Typography>Presale History</Index.Typography>
          <Index.Box className="select-box">
            <Index.TextField
              size="small"
              placeholder="Select Round"
              defaultValue={""}
              className="user-search-input select-round"
              select
              value={selectRound}
              onChange={handleSelectRound}
            >
              <Index.MenuItem value="All" className="round-select-menu-items">Select Round</Index.MenuItem>
             {roundData?.map((roundValue)=>(
               <Index.MenuItem value={roundValue} className="round-select-menu-items">{`Round ${roundValue}`}</Index.MenuItem>
             ))}
              {/* <Index.MenuItem value="2" className="round-select-menu-items">Round 2</Index.MenuItem>
              <Index.MenuItem value="3" className="round-select-menu-items">Round 3</Index.MenuItem>
              <Index.MenuItem value="4" className="round-select-menu-items">Round 4</Index.MenuItem> */}
          
            </Index.TextField>

            <Index.TextField
              size="small"
              placeholder="Search"
              className="user-search-input"
              onChange={handleSearchChange}
            />
          </Index.Box>
        </Index.Box>

        <Index.TableContainer className="private-pre-table-container">
          <Index.Table className="private-pre-top-table">
            <Index.TableHead
              className="private-pre-table-head"
              stickyHeader
              aria-label="sticky table"
            >
              <Index.TableRow>
                <Index.TableCell align="center">S.No</Index.TableCell>
                <Index.TableCell align="center">Round</Index.TableCell>
                <Index.TableCell align="center">Date</Index.TableCell>
                <Index.TableCell align="center">Address</Index.TableCell>
                <Index.TableCell align="center">Amount</Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody>
              {filterData?.length > 0 ? filterData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <Index.TableRow key={index}>
                    <Index.TableCell align="center">{page * rowsPerPage + index + 1}</Index.TableCell>
                    <Index.TableCell align="center">{item.round + 1}</Index.TableCell>
                    <Index.TableCell align="center">
                      {new Date(item.date).toLocaleString("en-US", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })}
                    </Index.TableCell>
                    <Index.TableCell align="center">
                      <Index.Box className="copy-icon-box">
                        <PagesIndex.LightTooltip title={item.address || ""}>
                          {abbreviateAddress(item.address)}
                        </PagesIndex.LightTooltip>
                        <PagesIndex.CopyToClipboard
                          text={item.address}
                          onCopy={() => handleCopy(index)}
                        >
                          <Index.ContentCopyIcon />
                        </PagesIndex.CopyToClipboard>
                        <Index.Typography sx={{ fontSize: "10px", width: "5px" }}>
                          {index === copySuccess ? 'Copy' : ""}
                        </Index.Typography>
                      </Index.Box>
                    </Index.TableCell>
                    <Index.TableCell align="center">{item.amount / 10**6}</Index.TableCell>
                  </Index.TableRow>
                )):
                <Index.TableRow>
                <Index.TableCell
                  colSpan={10}
                  align="center"
                  className="no-data-cell"
                >
                  No data found....
                </Index.TableCell>
              </Index.TableRow>}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>

        <Index.TablePagination
        className="table-list-pagination"
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filterData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Index.Box>
    </>
  );
};

export default PrivateSale;