
import footeronebg from '../assets/images/svg/footer-one-bg.svg'
import twitter from '../assets/images/svg/twitter.svg'
import skype from '../assets/images/svg/skype.svg'
import lindin from '../assets/images/svg/linkdin.svg'
import youtube from '../assets/images/svg/youtube.svg'
import instagram from '../assets/images/svg/instagram.svg'
import twoprevarrow from '../assets/images/svg/white-next-arrow.svg'
import twonextarrow from '../assets/images/svg/white-prev-arrow.svg'
import landingonebg from '../assets/images/png/ab-bg.jpg'
import logo from '../assets/images/svg/logo.svg'
import houseImage from '../assets/images/svg/house.svg'
import walletfour from '../assets/images/svg/wallet-four.svg'
import walletone from '../assets/images/svg/wallet-one.svg'
import HumburgerIcon from './images/svg/HumburgerIcon'
import telegramIcon from './images/svg/discord_icon.svg'
import discordIcon from './images/svg/telegram_icon.svg'
import twitterIcon from './images/svg/twtter_new_icon.svg'







const Svg = {
      landingonebg,
      footeronebg,
      discordIcon,
      telegramIcon,
      twitterIcon,
      twitter,
      instagram,
      youtube,
      skype,
      lindin,
      twoprevarrow,
      twonextarrow,
      logo,
      houseImage,
      walletfour,
      walletone,
      HumburgerIcon
}

export default Svg;