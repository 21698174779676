import React, { useEffect, useState } from 'react'
import Index from '../../../../Index'
import './information.css'
import { PagesIndex } from '../../../../../container/PagesIndex';
import { motion } from "framer-motion";
const Information = () => {
  const [infoData, setInfoData] = useState({});

  const getTopSectionData = async () => {
    try {
      const res = await PagesIndex.informationData();
      if (res?.data?.status === 200) {
        setInfoData(res?.data?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getTopSectionData();
  }, []);
  return (
    <>
      <Index.Box className='informationone-main'>
        <Index.Box className='container'>
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box display="grid" className="one-informationone-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 0, md: 0, lg: 5 }}>
            <Index.Box className="one-information-span" gridColumn={{ xs: "span 12", sm: "span 12", md: "span 7", lg: "span 5" }}>
                <Index.Box className="grid-col">
                <motion.div
                      animate={{ y: 100, opacity: 0, }}
                      transition={{ duration: 0.8 }}
                      whileInView={{ y: 0, opacity: 1, }}>
                  <Index.Box className="one-informationoneimg-img-sec">
                    <img src={Index.Png.DogImg} className='one-informationoneimg-img' alt='oneInformationImg' />
                    <img src={Index.Svg.houseImage} className='house-img' alt='oneInformationImg' />
                  </Index.Box>
                  </motion.div>
                </Index.Box>
              </Index.Box>
              <Index.Box className="one-information-span" gridColumn={{ xs: "span 12", sm: "span 12", md: "span 5", lg: "span 7" }} >
                <Index.Box className="grid-col">
                  <Index.Box className='one-informationone-left icon-content-title-after'>
                    <motion.div
                      animate={{ y: 100, opacity: 0, }}
                      transition={{ duration: 0.8 }}
                      whileInView={{ y: 0, opacity: 1, }}>
                      <Index.Box className='one-informationone-title info-title-icon info-light-title-main'>
                        <Index.Typography className='title-gradient icon-title-info info-titel-light' variant="h1">DOG WIF</Index.Typography>
                      </Index.Box>
                    </motion.div>
                    <motion.div
                      animate={{ y: 150, opacity: 0, }}
                      transition={{ duration: 0.8 }}
                      whileInView={{ y: 0, opacity: 1, }}>
                    <Index.Typography className='info-sub-title' variant="p" component="p">{infoData?.header}</Index.Typography>
                    </motion.div>
                    <motion.div
                      animate={{ y: 200, opacity: 0, }}
                      transition={{ duration: 0.8 }}
                      whileInView={{ y: 0, opacity: 1, }}>
                    <Index.Typography className='one-informationone-para' variant="p" component="p">
                     {infoData?.description}
                      </Index.Typography>
               
                    </motion.div>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}

export default Information

