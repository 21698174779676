import React, { useEffect, useState,useRef } from "react";
import Index from "../../../Index";
import debounce from 'lodash.debounce';
import "./social.css";
import { PagesIndex } from "../../../PagesIndex";

const Social = () => {
  const [loading, setLoading] = useState(false);
  const [socialData, setSocialData] = useState({});
  const descriptionRef = useRef(null);

  const getSocialData = async () => {
    try {
      const res = await PagesIndex.socialLinkData();
      if (res?.data?.status === 200) {
        setSocialData(res?.data?.data[0]);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getSocialData();
  }, []);

  const initialValues = {
    twitter: socialData ? socialData?.twitter?.link : "",
    linkedin: socialData ? socialData?.linkedin?.link : "",
    skype: socialData ? socialData?.skype?.link : "",
    youTube: socialData ? socialData?.youTube?.link : "",
    instagram: socialData ? socialData?.instagram?.link : "",
    description: socialData ? socialData?.description?.data : "",
  };

  const socialFormSubmit = async (values) => {
    setLoading(true);
    try {
      const res = await PagesIndex.adminSocialLinkUpdate(values);
      if (res?.data?.status === 200) {
        getSocialData();
      }
    } catch (error) {
      console.log(error, "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      console.log('Resized:', descriptionRef.current?.offsetHeight);
    }, 100);

    const observer = new ResizeObserver(handleResize);
    const currentRef = descriptionRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Index.Box className="presale-userlist">
        <Index.Box className="user-heading-box">
          <Index.Box className="user-heading">Social Links</Index.Box>
        </Index.Box>

        <Index.Box className="admin-edit-profile-main">
          <Index.Box className="admin-edit-profile-box social-main-box">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={socialFormSubmit}
              initialValues={initialValues}
              validationSchema={PagesIndex.AdminSocialLinksSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
                setFieldValue
              }) => (
                <form
                  component="form"
                  spacing={2}
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Index.Box>
                    <Index.Box className="edit-profile-content">
                      <Index.Box className="auth-body social-form-box">
                        <Index.Grid container spacing={3}>
                          
                        <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                              Description*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Description"
                                size="small"
                                multiline
                                rows={4}
                                maxRows={4}
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="description"
                                data-testid="mobileNumber-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                inputProps={{ maxLength: 512 }}
                                value={values.description}
                                onChange={handleChange}
                                helperText={
                                  touched.description && errors.description
                                }
                                error={Boolean(
                                  errors.description && touched.description
                                )}
                                ref={descriptionRef}
                              />
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                                Twitter*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Twitter"
                                size="small"
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="twitter"
                                data-testid="fullName-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                inputProps={{ maxLength: 128 }}
                                value={values.twitter}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (!inputValue.includes(' ')) {
                                    setFieldValue("twitter", inputValue);
                                  }
                                }}
                                helperText={touched.twitter && errors.twitter}
                                error={Boolean(
                                  errors.twitter && touched.twitter
                                )}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                                {/* Linkedin* */}
                                Discord*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Discord"
                                size="small"
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="linkedin"
                                data-testid="email-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                value={values.linkedin}
                                inputProps={{ maxLength: 128 }}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (!inputValue.includes(' ')) {
                                    setFieldValue("linkedin", inputValue);
                                  }
                                }}
                                helperText={touched.linkedin && errors.linkedin}
                                error={Boolean(
                                  errors.linkedin && touched.linkedin
                                )}
                              />
                            </Index.Box>
                          </Index.Grid>

                          {/* <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                                Skype*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Skype"
                                size="small"
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="skype"
                                data-testid="mobileNumber-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                value={values.skype}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (!inputValue.includes(' ')) {
                                    setFieldValue("skype", inputValue);
                                  }
                                }}
                                helperText={touched.skype && errors.skype}
                                error={Boolean(errors.skype && touched.skype)}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                                YouTube*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="YouTube"
                                size="small"
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="youTube"
                                data-testid="mobileNumber-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                value={values.youTube}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (!inputValue.includes(' ')) {
                                    setFieldValue("youTube", inputValue);
                                  }
                                }}
                                helperText={touched.youTube && errors.youTube}
                                error={Boolean(
                                  errors.youTube && touched.youTube
                                )}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={12} md={6} lg={6}>
                            <Index.Box className="form-group-prof">
                              <Index.Typography className="form-lable-prof">
                                Instagram*
                              </Index.Typography>
                              <Index.TextField
                                fullWidth
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Instagram"
                                size="small"
                                variant="filled"
                                className="form-input login-password profile-input-field"
                                name="instagram"
                                data-testid="mobileNumber-input"
                                autoComplete="off"
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                                value={values.instagram}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (!inputValue.includes(' ')) {
                                    setFieldValue("instagram", inputValue);
                                  }
                                }}
                                helperText={
                                  touched.instagram && errors.instagram
                                }
                                error={Boolean(
                                  errors.instagram && touched.instagram
                                )}
                              />
                            </Index.Box>
                          </Index.Grid> */}


                        </Index.Grid>
                        <Index.Box className="edit-btn-box">
                          <Index.Button
                            type={loading ? "button" : "submit"}
                            data-testid="button"
                            variant="contained"
                            className="edit-btn"
                          >
                            {loading ? (
                              <PagesIndex.AdminButtonLoader />
                            ) : (
                              <>
                              <Index.SaveAsIcon fontSize="small"/>
                              Submit
                              </>
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Social;
