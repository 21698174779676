import React, { useMemo, useState, useEffect } from "react";
import "./account.css";
import Index from "../../../Index";
import {
  Connection,
  PublicKey,
  clusterApiUrl,
  SystemProgram,
  SendTransactionError,
} from "@solana/web3.js";
import {
  Program,
  AnchorProvider,
  utils,
  web3,
  BN,
} from "@project-serum/anchor";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import {
  TOKEN_PROGRAM_ID,
  getAssociatedTokenAddressSync,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
} from "@solana/spl-token";
import { Buffer } from "buffer";
import idl from "../../../../idl/newwiffinaltest.json";
import { PagesIndex } from "../../../PagesIndex";

window.Buffer = Buffer;

const Account = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const wallet = useAnchorWallet();
  const [adminPublicKey, setAdminPublicKey] = useState(null);
  const [vestingAccounts, setVestingAccounts] = useState([]);
  const [vestingAccountAll, setVestingAccountAll] = useState({});
  const [filterData, setFilterData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectRound, setSelectRound] = useState("All");
  const connection = new Connection(clusterApiUrl("devnet"));
  const [roundData,setRoundData] = useState([]);
  const [btnLoding,setBtnLoading]=useState(false)

  const rounds = [0, 1, 2];

  const provider = useMemo(() => {
    if (!wallet) return null;
    return new AnchorProvider(
      connection,
      wallet,
      AnchorProvider.defaultOptions()
    );
  }, [wallet, connection]);

  const programID = new PublicKey(idl.metadata.address);
  const tokenMint = new PublicKey(process.env.REACT_APP_TOKEN_MINT);
  const STATE_SEEDS = utils.bytes.utf8.encode("state");
  const VEST_SEEDS = utils.bytes.utf8.encode("vesting");
  const PUB_POOL_SEEDS = utils.bytes.utf8.encode("public_pool");

  useEffect(() => {
    if (wallet) {
      fetchAdminPublicKey();
      fetchAllVestingAccounts();
    }
  }, [wallet]);

  const fetchAdminPublicKey = async () => {
    const program = new Program(idl, programID, provider);
    const [statePDA] = web3.PublicKey.findProgramAddressSync(
      [STATE_SEEDS],
      programID
    );
    const stateAccount = await program.account.state.fetch(statePDA);
    setAdminPublicKey(stateAccount.admin);
  };

  const getOrCreateTokenAccount = async (token, owner, isOffCurve = false) => {
    const tokenAccount = getAssociatedTokenAddressSync(
      token,
      owner,
      isOffCurve
    );
    let accountInfo = await provider.connection.getAccountInfo(tokenAccount);
    if (accountInfo == null) {
      const tx = new web3.Transaction().add(
        createAssociatedTokenAccountInstruction(
          wallet.publicKey,
          tokenAccount,
          owner,
          token
        )
      );
      await provider.sendAndConfirm(tx);
    }
    return tokenAccount;
  };

  const fetchVestingAccount = async (round) => {
    if (!wallet) {
      console.log("Please connect your wallet");
      return null;
    }

    const program = new Program(idl, programID, provider);
    const [vestingPDA] = web3.PublicKey.findProgramAddressSync(
      [VEST_SEEDS, Buffer.from([round]), wallet.publicKey.toBuffer()],
      program.programId
    );

    try {
      const account = await program.account.vesting.fetch(vestingPDA);
      console.log(`Vesting account fetched for round ${round}:`, account);
      return { account, vestingPDA };
    } catch (error) {
      console.error(`Vesting account not found for round ${round}:`, error);
      return null;
    }
  };

  const fetchAllVestingAccounts = async () => {
    const program = new Program(idl, programID, provider);
    const accountRound = await program.account.vesting.all();
    try {
      const accounts = {};
      for (const round of rounds) {
        const accountInfo = await fetchVestingAccount(round);
        if (accountInfo) {
          accounts[round] = accountInfo;
        }
      }
      setVestingAccountAll(accounts);
      const data = Object.keys(accounts).reduce((acc, curr) => {
        if (accounts[curr]?.account?.purchases?.length > 0) {
          const purchases = accounts[curr].account.purchases.map((ele, index) => ({
            rowIndex: index,
            vestingPDA:accounts[curr]?.vestingPDA,
            ...ele,
          }));
          return acc.concat(purchases);
        }
        return acc;
      }, []);

      setVestingAccounts(data?.sort((a, b) => b?.startTime - a?.startTime));
      
      const initialRoundData = accountRound?.map((_, index) => index + 1);
      setRoundData(initialRoundData);
    } catch (error) {
      console.log(error,"error");
    }
  
  };

  const claimTokens = async (round, index) => {
    console.log("ROund : ", round)
    try {
      if (!wallet) {
        alert("Please connect your wallet");
      }

      const program = new Program(idl, programID, provider);
      const [statePDA] = web3.PublicKey.findProgramAddressSync(
        [STATE_SEEDS],
        programID
      );
      const [pubsupPDA] = web3.PublicKey.findProgramAddressSync(
        [PUB_POOL_SEEDS, adminPublicKey.toBuffer()],
        program.programId
      );

      const pubsupAta = await getOrCreateTokenAccount(
        tokenMint,
        pubsupPDA,
        true
      );
      const buyerAta = await getOrCreateTokenAccount(
        tokenMint,
        wallet.publicKey
      );

      const vestingAccountInfo = vestingAccounts?.filter((ele)=> ele?.round == round);
      if (!vestingAccountInfo) {
        console.log(`No vesting account found for round ${round+1}`);
        return;
      }

      const { account: vestingAccount, vestingPDA } = vestingAccountInfo;
      const transaction = new web3.Transaction();

      const purchase = vestingAccountInfo?.find((ele)=> ele?.rowIndex == index);
      if (purchase?.claimed) {
        console.log(`Purchase ${index} in round ${round} already claimed`);
        return;
      }

      const claimableTime = new BN(purchase.startTime).add(new BN(5 * 60));
      const currentTime = Math.floor(Date.now() / 1000);


      if (currentTime >= claimableTime.toNumber()) {
        try {
          const claimTx = await program.methods
            .claim(round, new BN(index))
            .accounts({
              claimant: wallet.publicKey,
              claimantAta: buyerAta,
              state: statePDA,
              pubsupPda: pubsupPDA,
              pubsupAta: pubsupAta,
              mint: tokenMint,
              vesting: purchase?.vestingPDA,
              tokenProgram: TOKEN_PROGRAM_ID,
              systemProgram: SystemProgram.programId,
              associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
            })
            .instruction();

          transaction.add(claimTx);

          const { blockhash } = await connection.getLatestBlockhash();
          transaction.recentBlockhash = blockhash;
          transaction.feePayer = wallet.publicKey;

          const signedTransaction = await wallet.signTransaction(transaction);
          const signature = await provider.connection.sendRawTransaction(
            signedTransaction.serialize()
          );
          await provider.connection.confirmTransaction(signature, "processed");
          console.log(
            `Claim complete for purchase ${index} in round ${
              round + 1
            }. Transaction Signature:`,
            signature
          );
          PagesIndex.toast?.success("Token claimed successfully");
          setTimeout(() => {
            window?.location?.reload();
          }, 10000);
        } catch (signError) {
          console.error(
            `Error signing and sending transaction for purchase ${index} in round ${
              round + 1
            }:`,
            signError
          );
          if (signError instanceof SendTransactionError) {
            console.log("Transaction failed: ", signError.getLogs());
            PagesIndex.toast.error("Transaction failed: ", signError.getLogs());
          }
        }
      } else {
        PagesIndex.toast.error("User will be claimed after 5 minutes from purchase token");
      }
    } catch (error) {
      PagesIndex.toast.error(error?.message);
    }
  };

  const claimAllTokens = async () => {
    try {
      if (!wallet) {
        alert("Please connect your wallet");
      }
      setBtnLoading(true)
      const program = new Program(idl, programID, provider);
      const [statePDA] = web3.PublicKey.findProgramAddressSync(
        [STATE_SEEDS],
        programID
      );
      const [pubsupPDA] = web3.PublicKey.findProgramAddressSync(
        [PUB_POOL_SEEDS, adminPublicKey.toBuffer()],
        program.programId
      );

      const pubsupAta = await getOrCreateTokenAccount(
        tokenMint,
        pubsupPDA,
        true
      );
      const buyerAta = await getOrCreateTokenAccount(
        tokenMint,
        wallet.publicKey
      );

      for (const round of rounds) {
        const vestingAccountInfo = vestingAccountAll[round];
        if (!vestingAccountInfo) {
          console.log(`No vesting account found for round ${round+1}`);
          continue;
        }

        const { account: vestingAccount, vestingPDA } = vestingAccountInfo;
        const transaction = new web3.Transaction();

        for (const [index, purchase] of vestingAccount.purchases.entries()) {
          if (purchase.claimed) {
            console.log(
              `Purchase ${index} in round ${round + 1} already claimed`
            );
            continue;
          }

          const claimableTime = new BN(purchase.startTime).add(new BN(30 * 60));
          const currentTime = Math.floor(Date.now() / 1000);

          if (currentTime >= claimableTime.toNumber()) {
            try {
              const claimTx = await program.methods
                .claim(round, new BN(index))
                .accounts({
                  claimant: wallet.publicKey,
                  claimantAta: buyerAta,
                  state: statePDA,
                  pubsupPda: pubsupPDA,
                  pubsupAta: pubsupAta,
                  mint: tokenMint,
                  vesting: vestingPDA,
                  tokenProgram: TOKEN_PROGRAM_ID,
                  systemProgram: SystemProgram.programId,
                  associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                })
                .instruction();

              transaction.add(claimTx);
            } catch (e) {
              console.log(
                `Purchase ${index} in round ${round} not claimable yet at time: ${claimableTime.toNumber()}`
              );
              PagesIndex.toast?.error(
                `Purchase ${index} in round ${round} not claimable yet at time: ${claimableTime.toNumber()}`
              );
            }
          } else {
            console.log(
              `Purchase ${index} in round ${round} not yet claimable at time: ${currentTime}. It will be claimable at ${claimableTime.toNumber()}`
            );
          }
        }

        if (transaction.instructions.length > 0) {
          try {
            const { blockhash } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            transaction.feePayer = wallet.publicKey;

            const signedTransaction = await wallet.signTransaction(transaction);
            const signature = await provider.connection.sendRawTransaction(
              signedTransaction.serialize()
            );
            await provider.connection.confirmTransaction(
              signature,
              "processed"
            );
            console.log(
              `Claim complete for round ${round}. Transaction Signature:`,
              signature
            );
            PagesIndex.toast.success("Claim all tokens successfully");
            setBtnLoading(false)
            setTimeout(() => {
              window?.location?.reload();
            }, 7000);

            const buyerTokenAccountInfo =
              await provider.connection.getParsedAccountInfo(buyerAta);
            if (
              buyerTokenAccountInfo.value &&
              buyerTokenAccountInfo.value.data &&
              "parsed" in buyerTokenAccountInfo.value.data
            ) {
              const buyerTokenAmount =
                buyerTokenAccountInfo.value.data.parsed.info.tokenAmount
                  .uiAmount;
              if (buyerTokenAmount > 0) {
                console.log("Buyer should have tokens after claiming");
              }
            }
          } catch (signError) {
            console.error(
              `Error signing and sending transaction for round ${round}:`,
              signError
            );
            // PagesIndex.toast?.error(
            //   `Error signing and sending transaction for round ${round}:`,
            //   signError
            // );

            if (signError instanceof SendTransactionError) {
              console.log("Transaction failed: ", signError.getLogs());
              PagesIndex.toast?.error(
                `Transaction failed: ${signError.getLogs()}`
              );
            }
          }
        } else {
          PagesIndex.toast?.error(
            `No claimable transactions found for round ${round+1}`
          );
          console.log(`No claimable transactions found for round ${round}.`);
        }
      }

      console.log(
        "All claimable tokens have been processed. Please check the console for more details."
      );
  
    } catch (error) {
      PagesIndex.toast.error(error?.message);
    }
    setBtnLoading(false)
  };


  const handleSelectRound = (e) => {
    setSelectRound(e.target.value);
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.trim().toLowerCase());
    setPage(0);
  };

  useEffect(() => {
    const filteredData = vestingAccounts
      .filter((item) => selectRound === "All" || item.round + 1 === parseInt(selectRound))
       .filter((item) => {
      const amountString = item.amount ? (item.amount / 10**6).toString() : "";
      const startDate = item?.startTime
      ? new Date(item?.startTime).toLocaleString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      : "";
      const endDate = item?.startTime ?new Date(
        (item?.startTime.toNumber() + 5 * 60) * 1000
      )?.toLocaleString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }):"";
      return (
        startDate?.toLowerCase().includes(searchTerm.toLowerCase())  ||
        endDate?.toLowerCase().includes(searchTerm.toLowerCase())  ||
        amountString?.includes(searchTerm) ||
        (item?.claimed === true && (searchTerm?.toLowerCase() === 'claimed' || searchTerm?.toLowerCase() === 'yes')) ||
        (item?.claimed === false && (searchTerm?.toLowerCase() === 'claim' || searchTerm?.toLowerCase() === 'no'))
      )

      });
    setFilterData(filteredData);
    setPage(0);
  }, [selectRound, searchTerm, vestingAccounts]);


  return (
    <>
      <Index.Box className="presale-wraper account-main-div">
        <Index.Box className="login-section account-bg-box">
          <Index.Box className="admin-main-bg account-page">
            <Index.Box className="my-account-heading-box">
              <Index.Typography className="my-account-heading">
                My Account
              </Index.Typography>
        <Index.Box className="my-account-claim-btn">
              <Index.Select
              size="small"
              placeholder="Select Round"
              className="user-search-input select-round"
              select
              value={selectRound}
              onChange={handleSelectRound}
              id="account-select"
              MenuProps={{
                classes: {
                  paper: 'account-select-list-menu',
                },
              }}
            >
              <Index.MenuItem value="All" className="round-select-menu-items">Select Round</Index.MenuItem>
              {roundData?.map((roundValue)=>(
               <Index.MenuItem value={roundValue} className="round-select-menu-items">{`Round ${roundValue}`}</Index.MenuItem>
             ))}
            </Index.Select>

                <Index.TextField size="small" className="my-account-search-input" placeholder="search" onChange={handleSearchChange}/>
                {/* <Index.Button onClick={() => claimAllTokens()} disabled={btnLoding}>
                  Claim All Tokens
                </Index.Button> */}
              </Index.Box>
            </Index.Box>

            {/* <Index.Box className="stat-box">
              <Index.Box className="stat-card">
                <Index.Box className="stat-card-icon-box">
                  <img
                    src={Index.Svg.logo}
                    className="stat-card-icon"
                    alt="logo"
                  />
                </Index.Box>
                <Index.Box className="text-wrape">
                  <Index.Typography component="h6">
                    146250000.00
                  </Index.Typography>
                  <Index.Typography component="p">Total Token</Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="stat-card unlock">
                <Index.Box className="stat-card-icon-box">
                  <img
                    src={Index.Svg.logo}
                    className="stat-card-icon"
                    alt="logo"
                  />
                </Index.Box>
                <Index.Box className="text-wrape">
                  <Index.Typography component="h6">
                    146252313.00
                  </Index.Typography>
                  <Index.Typography component="p">
                    Total Supplied Token
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="stat-card available">
                <Index.Box className="stat-card-icon-box">
                  <img
                    src={Index.Svg.logo}
                    className="stat-card-icon"
                    alt="logo"
                  />
                </Index.Box>
                <Index.Box className="text-wrape">
                  <Index.Typography component="h6">1252125</Index.Typography>
                  <Index.Typography component="p">Raise USDT</Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box> */}

            <Index.TableContainer className="account-pre-table-container">
              <Index.Table className="account-pre-top-table table-scroll">
                <Index.TableHead
                  className="account-pre-table-head"
                  stickyHeader
                  aria-label="sticky table"
                >
                  <Index.TableRow>
                    <Index.TableCell align="center"> S.No</Index.TableCell>
                    <Index.TableCell align="center">Round</Index.TableCell>
                    <Index.TableCell align="center">Tokens</Index.TableCell>
                    <Index.TableCell align="center">Start Date</Index.TableCell>
                    <Index.TableCell align="center">End Date</Index.TableCell>
                    <Index.TableCell align="center">Claimed</Index.TableCell>
                    <Index.TableCell align="center">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>

                <Index.TableBody className="table-body-screen">
                  {filterData?.length > 0 ?
                    filterData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((purchase, index) => (
                        <Index.TableRow key={index}>
                       <Index.TableCell align="center">
                          {page * rowsPerPage + index + 1}
                          </Index.TableCell>

                          <Index.TableCell align="center">
                            {purchase?.round + 1}
                          </Index.TableCell>
                   
                          <Index.TableCell align="center">
                            {(purchase?.amount?.toString())/10**6}
                          </Index.TableCell>
                          <Index.TableCell align="center">
                            {new Date(
                              purchase?.startTime?.toNumber() * 1000
                            )?.toLocaleString("en-US", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </Index.TableCell>
                          <Index.TableCell align="center">
                            {new Date(
                              (purchase.startTime.toNumber() + 5 * 60) * 1000
                            )?.toLocaleString("en-US", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </Index.TableCell>
                          <Index.TableCell align="center">
                            {purchase.claimed ? "Yes" : "No"}
                          </Index.TableCell>
                          <Index.TableCell align="center">
                            <Index.Button
                              onClick={() =>
                                claimTokens(purchase?.round, purchase?.rowIndex)
                              }
                              disabled={purchase.claimed}
                            >
                              {purchase.claimed ? "Claimed" : "Claim"}
                            </Index.Button>
                          </Index.TableCell>
                        </Index.TableRow>
                      )):
                      <Index.TableRow>
                      <Index.TableCell
                        colSpan={10}
                        align="center"
                        className="no-data-cell"
                      >
                        No data found....
                      </Index.TableCell>
                    </Index.TableRow>
                      }

                  {/* {rounds?.map(
                    (round) =>
                      vestingAccounts[round] && (
                        <React.Fragment key={round}>
                          {vestingAccounts[round]?.account?.purchases
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            ?.map((purchase, index) => (
                              <Index.TableRow key={index}>
                                {console.log(purchase, "5555555555555")}
                                <Index.TableCell align="center">
                                  {purchase?.round + 1}
                                </Index.TableCell>
                                <Index.TableCell align="center">
                                  {page * rowsPerPage + index + 1}
                                </Index.TableCell>
                                <Index.TableCell align="center">
                                  {purchase?.amount?.toString()}
                                </Index.TableCell>
                                <Index.TableCell align="center">
                                  {new Date(
                                    purchase?.startTime?.toNumber() * 1000
                                  )?.toLocaleString("en-US", {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}
                                </Index.TableCell>
                                <Index.TableCell align="center">
                                  {new Date(
                                    (purchase.startTime.toNumber() + 5 * 60) *
                                      1000
                                  )?.toLocaleString("en-US", {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })}
                                </Index.TableCell>
                                <Index.TableCell align="center">
                                  {purchase.claimed ? "Yes" : "No"}
                                </Index.TableCell>
                                <Index.TableCell align="center">
                                  <Index.Button
                                    onClick={() =>
                                      claimTokens(
                                        purchase?.round,
                                        page * rowsPerPage + index + 1
                                      )
                                    }
                                    disabled={purchase.claimed}
                                  >
                                    {purchase.claimed ? "Claimed" : "Claim"}
                                  </Index.Button>
                                </Index.TableCell>
                              </Index.TableRow>
                            ))}
                        </React.Fragment>
                      )
                  )} */}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>

            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              className="my-accout-custom"
              count={filterData?.length ||0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              MenuProps={{
                classes: {
                  paper: 'account-select-list-menu',
                },
              }}
            />

            <Index.Box></Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Account;
