import React, { useEffect, useState, useMemo } from 'react';
import Index from '../../../../Index';
import '../roadmap/roadmap.css';
import { motion } from 'framer-motion';
import { PagesIndex } from '../../../../../container/PagesIndex';

function Roadmap() {
  const [roadMapData, setRoadMapData] = useState([]);
  const [expandedRoadmap, setExpandedRoadmap] = useState(null);

  const getRoadMapData = async () => {
    try {
      const res = await PagesIndex.adminRoadMapData();
      if (res?.data?.status === 200) {
        setRoadMapData(res?.data?.data);
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    getRoadMapData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short' };
    return new Date(dateString)?.toLocaleDateString('en-US', options);
  };

  const formattedRoadMapData = useMemo(
    () => roadMapData.map(item => ({ ...item, formattedDate: formatDate(item?.date) })),
    [roadMapData]
  );

  const toggleExpand = (index) => {
    setExpandedRoadmap(expandedRoadmap === index ? null : index);
  };

  // const renderDescription = (description, index) => {
  //   if (!description) return '';
  //   if (expandedRoadmap === index) {
  //     return description;
  //   } else {
  //     return description?.slice(0, 130) + (description?.length > 130 ? '...' : '');
  //   }
  // };

  const renderDescription = (description, index) => {
    if (!description) return '';
  
    const formattedDescription = description
      ?.split('\n')
      ?.map((line, idx) => (
        <React.Fragment key={idx}>
          {line?.replace(/ {2,}/g, (spaces) => '\u00A0'.repeat(spaces?.length))}
          <br />
        </React.Fragment>
      ));
  
    if (expandedRoadmap === index) {
      return formattedDescription;
    } else {
      const truncated = description?.slice(0, 130);
      const truncatedDescription = truncated?.split('\n')?.map((line, idx) => (
        <React.Fragment key={idx}>
          {line?.replace(/ {2,}/g, (spaces) => '\u00A0'.repeat(spaces?.length))}
          <br />
        </React.Fragment>
      ));
  
      return (
        <>
          {truncatedDescription}
          {description.length > 130 && '...'}
        </>
      );
    }
  };
  
  

  return (
    <>
      <Index.Box className="roadmap-three-main">
        <Index.Box className="container">
          <motion.div
            animate={{ y: 100, opacity: 0 }}
            transition={{ duration: 0.8 }}
            whileInView={{ y: 0, opacity: 1 }}
          >
            <Index.Box className="icon-content-title-after roadmpa-title-after">
              <Index.Box className="roadmap-three-main-title info-title-icon info-light-title-main">
                <Index.Typography className="title-gradient roadmap-three-heading icon-title-info info-titel-light" variant="h1">
                  Roadmap
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </motion.div>
          <Index.Box className="roadmap-three-list-main">
            <Index.List className="roadmap-three-ul">
              {formattedRoadMapData.map((item, index) => (
                <Index.ListItem
                  key={index}
                  className={`roadmap-three-li ${index % 2 === 0 ? 'roadmap-three-li-left' : 'roadmap-three-li-right'}`}
                >
                  <motion.div
                    animate={{ x: index % 2 === 0 ? -200 : 200, opacity: 0 }}
                    transition={{ duration: 0.8 }}
                    whileInView={{ x: 0, opacity: 1 }}
                  >
                    <Index.Box className="roadmap-three-year-box">
                      <Index.Typography className="roadmap-three-year-text">{item.formattedDate}</Index.Typography>
                    </Index.Box>
                    <Index.Box className="roadmap-three-content-box">
                      <Index.Typography className="roadmap-three-content-title">{item.title}</Index.Typography>
                      <Index.Typography className="roadmap-three-content-para">
                        {renderDescription(item?.description, index)}
                        {item.description?.length > 130 && (
                          <span onClick={() => toggleExpand(index)} className='view-more-btn'>
                            {expandedRoadmap === index ? ' View Less' : 'View More'}
                          </span>
                        )}
                      </Index.Typography>
                    </Index.Box>
                  </motion.div>
                </Index.ListItem>
              ))}
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Roadmap;
