import React from 'react'
import Index from '../../../../component/Index'

const Home = () => {
  return (
    <>

      <Index.Box className="hero-one-slider-sec">
        <Index.HeroSection />
      </Index.Box>

      <Index.Box className="information-sec" id='section-1'>
        <Index.Information />
      </Index.Box>

      <Index.Box className="features-one-main-sec" id='section-2'>
      <Index.Features/>
      </Index.Box>

      {/* <Index.Box className='anaylitcs-sec' id='section-3'>
        <Index.Analytics />
      </Index.Box> */}

      <Index.Box className='roadmap-one-main-sec' id='section-4'>
        <Index.Roadmap />
      </Index.Box>

      <Index.Box className="tokenomice-one-main-sec" id='section-5'>
        <Index.Tokenomics />
      </Index.Box>

    </>



  )
}

export default Home