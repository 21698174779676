import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import "./footer.css";
import { motion } from "framer-motion";
import { PagesIndex } from "../../../../container/PagesIndex";

export default function Footer() {
  const [socialData, setSocialData] = useState({});
  const getSocialData = async () => {
    try {
      const res = await PagesIndex.socialLinkData();
      if (res?.data?.status === 200) {
        setSocialData(res?.data?.data[0]);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getSocialData();
  }, []);

  return (
    <>
      <Index.Box className="footer-one-gradient-border"></Index.Box>
      <Index.Box className="footer-light-content-details">
        <Index.Box className="bg-footer-light-top">
          <Index.Box className="container">
            <Index.Box className="pd-light-content-footer">
              <Index.Box className="grid-sec grid-footer-content">
                <Index.Box
                  sx={{ width: 1 }}
                  className="grid-main grid-main-footer-light"
                >
                  <Index.Box
                    display="grid"
                    className="footer-light-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 5",
                        lg: "span 7",
                      }}
                      className="grid-column col-light-footer"
                    >
                      <Index.Box className="grid-col light-col-grid">
                        <Index.Box className="brand-light-left-col">
                          <motion.div
                            animate={{ y: 100, opacity: 0 }}
                            transition={{ duration: 0.8 }}
                            whileInView={{ y: 0, opacity: 1 }}
                          >
                            <Index.Box className="footer-logo-box">
                              <img
                                src={Index.Svg.logo}
                                alt="logo"
                                className="footer-logo"
                              />
                            </Index.Box>
                          </motion.div>
                          <Index.Box className="footer-add-light-logo">
                            <motion.div
                              animate={{ y: 150, opacity: 0 }}
                              transition={{ duration: 0.8 }}
                              whileInView={{ y: 0, opacity: 1 }}
                            >
                              <Index.Typography
                                className="list-add-logo-light"
                                component="p"
                                variant="p"
                              >
                                {socialData?.description?.data}
                                {/* Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio */}
                              </Index.Typography>
                            </motion.div>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    {/* <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 3", lg: "span 3" }} className="grid-column col-light-footer">
                      <Index.Box className="grid-col light-col-grid">
                        <Index.Box className="other-link-footer-light">
                          <motion.div
                            animate={{ y: 100, opacity: 0, }}
                            transition={{ duration: 0.8 }}
                            whileInView={{ y: 0, opacity: 1, }}>
                            <Index.Typography className="list-footer-title-light" component='p' variant='p'>
                              Other Links
                            </Index.Typography>
                          </motion.div>
                          <Index.List class="light-center-other-link">
                            <Index.ListItem className="listitem-light-content">
                              <motion.div
                                animate={{ y: 150, opacity: 0, }}
                                transition={{ duration: 0.8 }}
                                whileInView={{ y: 0, opacity: 1, }}>
                                <Index.Link className='refrence-link-light-footer text-decoration-none'>
                                  Privacy Policy
                                </Index.Link>
                              </motion.div>
                            </Index.ListItem>
                            <Index.ListItem className="listitem-light-content">
                              <motion.div
                                animate={{ y: 200, opacity: 0, }}
                                transition={{ duration: 0.8 }}
                                whileInView={{ y: 0, opacity: 1, }}>
                                <Index.Link className='refrence-link-light-footer text-decoration-none'>
                                  Term And Conditions
                                </Index.Link>
                              </motion.div>
                            </Index.ListItem>
                            <Index.ListItem className="listitem-light-content">
                              <motion.div
                                animate={{ y: 250, opacity: 0, }}
                                transition={{ duration: 0.8 }}
                                whileInView={{ y: 0, opacity: 1, }}>
                                <Index.Link className='refrence-link-light-footer text-decoration-none'>
                                  Help And Support
                                </Index.Link>
                              </motion.div>
                            </Index.ListItem>
                          </Index.List>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box> */}
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 7",
                        lg: "span 5",
                      }}
                      className="grid-column col-light-footer"
                    >
                      <Index.Box className="grid-col light-col-grid">
                        <Index.Box className="list-social-icon-footer">
                          <Index.Box className="other-link-footer-light">
                            <motion.div
                              animate={{ y: 100, opacity: 0 }}
                              transition={{ duration: 0.8 }}
                              whileInView={{ y: 0, opacity: 1 }}
                            >
                              <Index.Typography
                                className="list-footer-title-light"
                                component="p"
                                variant="p"
                              >
                                Social Media
                              </Index.Typography>
                            </motion.div>
                            <Index.Box className="light-footer-social-icon footer-light-social-main">
                              <Index.List className="footer-light-social-ul">
                                <Index.ListItem className="footer-light-socil-li">
                                  <motion.div
                                    animate={{ y: 100, opacity: 0 }}
                                    transition={{ duration: 0.8 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                  >
                                      <Index.Link
                                        className="footer-light-social-link text-decoration-none"
                                        to={socialData?.twitter?.link}
                                        target="_blank"
                                      >
                                        <Index.Box className="footer-light-social-box">
                                          <img
                                            src={Index.Svg.twitterIcon}
                                            className="footer-one-social-icons hide-hover-light"
                                            alt=""
                                          />
                                        </Index.Box>
                                      </Index.Link>
                                  </motion.div>
                                </Index.ListItem>

                                <Index.ListItem className="footer-light-socil-li">
                                  <motion.div
                                    animate={{ y: 100, opacity: 0 }}
                                    transition={{ duration: 0.8 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                  >
                                      <Index.Link
                                        className="footer-light-social-link text-decoration-none"
                                        to={socialData?.linkedin?.link}
                                        target="_blank"
                                      >
                                        <Index.Box className="footer-light-social-box">
                                          <img
                                            src={Index.Svg.discordIcon}
                                            className="footer-one-social-icons hide-hover-light"
                                            alt=""
                                          />
                                        </Index.Box>
                                      </Index.Link>
                                  </motion.div>
                                </Index.ListItem>
                                
                              </Index.List>

                              {/* <Index.List className="footer-light-social-ul">
                                <Index.ListItem className="footer-light-socil-li">
                                  <motion.div
                                    animate={{ y: 100, opacity: 0, }}
                                    transition={{ duration: 0.8 }}
                                    whileInView={{ y: 0, opacity: 1, }}>
                                      <PagesIndex.LightTooltip title="Tiwtter">
                                    <Index.Link className='footer-light-social-link text-decoration-none' to={socialData?.twitter?.link} target="_blank">
                                      <Index.Box className="footer-light-social-box">
                                        <img src={Index.Svg.twitter} className='footer-one-social-icons hide-hover-light' alt='' />
                                      </Index.Box>
                                    </Index.Link>
                                    </PagesIndex.LightTooltip>
                                  </motion.div>
                                </Index.ListItem>
                                <Index.ListItem className="footer-light-socil-li">
                                  <motion.div
                                    animate={{ y: 150, opacity: 0, }}
                                    transition={{ duration: 0.8 }}
                                    whileInView={{ y: 0, opacity: 1, }}>
                                      <PagesIndex.LightTooltip title="Linkedin">
                                    <Index.Link className='footer-light-social-link text-decoration-none' to={socialData?.linkedin?.link} target="_blank">
                                      <Index.Box className="footer-light-social-box">
                                        <img src={Index.Svg.lindin} className='footer-one-social-icons hide-hover-light' alt='' />
                                      </Index.Box>
                                    </Index.Link>
                                    </PagesIndex.LightTooltip>
                                  </motion.div>
                                </Index.ListItem>
                                <Index.ListItem className="footer-light-socil-li">
                                  <motion.div
                                    animate={{ y: 200, opacity: 0, }}
                                    transition={{ duration: 0.8 }}
                                    whileInView={{ y: 0, opacity: 1, }}>
                                      <PagesIndex.LightTooltip title="Skype">
                                    <Index.Link className='footer-light-social-link text-decoration-none' to={socialData?.skype?.link} target="_blank">
                                      <Index.Box className="footer-light-social-box">
                                        <img src={Index.Svg.skype} className='footer-one-social-icons hide-hover-light' alt='' />
                                      </Index.Box>
                                    </Index.Link>
                                    </PagesIndex.LightTooltip>
                                  </motion.div>
                                </Index.ListItem>
                                <Index.ListItem className="footer-light-socil-li">
                                  <motion.div
                                    animate={{ y: 250, opacity: 0, }}
                                    transition={{ duration: 0.8 }}
                                    whileInView={{ y: 0, opacity: 1, }}>
                                      <PagesIndex.LightTooltip title="Youtube">
                                    <Index.Link className='footer-light-social-link text-decoration-none' to={socialData?.youTube?.link} target="_blank">
                                      <Index.Box className="footer-light-social-box">
                                        <img src={Index.Svg.youtube} className='footer-one-social-icons hide-hover-light' alt='' />
                                      </Index.Box>
                                    </Index.Link>
                                    </PagesIndex.LightTooltip>
                                  </motion.div>
                                </Index.ListItem>
                                <Index.ListItem className="footer-light-socil-li">
                                  <motion.div
                                    animate={{ y: 300, opacity: 0, }}
                                    transition={{ duration: 0.8 }}
                                    whileInView={{ y: 0, opacity: 1, }}>
                                      <PagesIndex.LightTooltip title="Instagram">
                                    <Index.Link className='footer-light-social-link text-decoration-none' to={socialData?.instagram?.link} target="_blank">
                                      <Index.Box className="footer-light-social-box">
                                        <img src={Index.Svg.instagram} className='footer-one-social-icons hide-hover-light' alt='' />
                                      </Index.Box>
                                    </Index.Link>
                                    </PagesIndex.LightTooltip>
                                  </motion.div>
                                </Index.ListItem>
                              </Index.List> */}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* <Index.Box className="bg-footer-light-bottom">
            <Index.Box className="container">
              <Index.Box className="copyright-light-content">
                <Index.Typography component='p' variant='p'>Copyright@2023 Brand</Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box> */}
        </Index.Box>
      </Index.Box>
    </>
  );
}
