import * as Yup from "yup";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const noLeadingSpecialChar = /^[^.,?].*$/;
const noRepeatedSpecialChar = /^(?!.*([!@#$%^&*()_+{}\[\]:;"'<>,.?/~`-])\1).*$/;


Yup.addMethod(Yup.string, 'noLeadingSpecialChar', function(field) {
  return this.test({
    name: 'no-leading-special-char',
    message: `${field} cannot start with special characters`,
    test: function(value) {
      if (value) {
        return noLeadingSpecialChar.test(value);
      }
      return true;
    },
  });
});

Yup.addMethod(Yup.string, 'noRepeatedSpecialChar', function(field) {
  return this.test({
    name: 'no-repeated-special-char',
    message: `${field} cannot allow repeated special characters at the same time`,
    test: function(value) {
      if (value) {
        return noRepeatedSpecialChar.test(value);
      }
      return true;
    },
  });
});

  Yup.addMethod(Yup.string, 'noLeadingSpace', function(field){
    return this.test({
      name: 'no-leading-space',
      message:`${field} cannot start with a space`,
      test: function(value) {
        if (value) {
          return !value.startsWith(' ');
        }
        return true; 
      },
    });
  });


Yup.addMethod(Yup.string, 'noBlankLines', function (fieldName) {
  return this.test('no-blank-lines', `${fieldName} should not contain blank lines`, function (value) {
    const { path, createError } = this;
    const hasBlankLines = value && /(^\s*$\n?)/gm.test(value);
    return hasBlankLines
      ? createError({ path, message: `${fieldName} should not contain blank lines` })
      : true;
  });
});



export const AdminLoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(" Please enter a valid email")
    .matches(
      /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/i,
      " Please enter a valid email"
    )
    .required(" Please enter email"),

  password: Yup.string().required(" Please enter password"),
});

export const AdminForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(" Please enter a valid email")
    .matches(
      /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/i,
      " Please enter a valid email"
    )
    .required(" Please enter your email"),
});

export const AdminResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(" Please enter your new password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/,
      " Password must be 8 characters, one capital letter, one special character and one number "
    )
    .max(32, " New password cannot exceed 32 characters"),

  confirm_password: Yup.string()
    .required(" Please enter your confirm password")
    .oneOf(
      [Yup.ref("password")],
      " New password and confirm password should be the same"
    ),
});

export const AdminChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(" Please enter old password"),
  newPassword: Yup.string()
    .required(" Please enter new password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/,
      " Password must be 8 characters, one capital letter, one special character and one number "
    )
    .max(32, " New password cannot exceed 32 characters"),

  confirmPassword: Yup.string()
    .required(" Please enter confirm password")
    .oneOf(
      [Yup.ref("newPassword")],
      " New password and confirm password should be the same"
    ),
});

export const AdminProfileSchema = Yup.object().shape({
  profile: Yup.string().required("Please Select profile image (only jpg, jpeg, and png files are allowed)"),
  mobileNumber: Yup.string().required("Please enter number").min(10, "Number should be 10 digits"),
  name: Yup.string()
    .required("Please enter name")
    .transform((value) => value.replace(/\s/g, ""))
    .min(3, "Name must be at least 3 characters")
    .max(32, " Name cannot exceed 32 characters"),
  email: Yup.string()
    .required("Please enter email")
    .email("Please enter a valid email")
    .matches(emailRegex, "Please enter a valid email"),
});


// export const AdminSocialLinksSchema = Yup.object().shape({
//   twitter: Yup.string().url('Please enter a valid twitter link').required("Please enter twitter link "),
//   linkedin: Yup.string().url('Please enter a valid linkedin link').required("Please enter linkedin link "),
//   skype: Yup.string().url('Please enter a valid skype link').required("Please enter skype link "),
//   youTube: Yup.string().url('Please enter a valid youtube link').required("Please enter youtube link "),
//   instagram: Yup.string().url('Please enter a valid instagram link').required("Please enter instagram link "),
//   description: Yup.string().required("Please enter description").noLeadingSpace("Description"),
// });

const urlWithDotCom = (message) => {
  return Yup.string().url(message)
    .test(
      'has-dot-com',
      message,
      (value) => value && /\.(com|net|org)(.*)$/.test(value)
    );
};

export const AdminSocialLinksSchema = Yup.object().shape({
  twitter: urlWithDotCom('Please enter a valid twitter link').required("Please enter twitter link "),
  linkedin: urlWithDotCom('Please enter a valid linkedin link').required("Please enter discord link "),
  // skype: urlWithDotCom('Please enter a valid skype link').required("Please enter skype link "),
  // youTube: urlWithDotCom('Please enter a valid youtube link').required("Please enter youtube link "),
  // instagram: urlWithDotCom('Please enter a valid instagram link').required("Please enter instagram link "),
  description: Yup.string().required("Please enter description").noLeadingSpace("Description").noBlankLines("Description")
  .noLeadingSpecialChar("Description")
  .noRepeatedSpecialChar("Description"),
});


export const AdminTopSectionSchema = Yup.object().shape({
  header: Yup.string().required("Please enter title").noLeadingSpace("Title")
  .noLeadingSpecialChar("Title")
  .noRepeatedSpecialChar("Title"),
  description: Yup.string().required("Please enter description").noLeadingSpace("Description").noBlankLines("Description")
  .noLeadingSpecialChar("Description")
  .noRepeatedSpecialChar("Description"),
  pdf: Yup.string().required("Please upload white paper  (only pdf file allowed)"),

});

export const AdminInformationSchema = Yup.object().shape({
  header: Yup.string().required("Please enter title").noLeadingSpace("Title")
  .noLeadingSpecialChar("Title")
  .noRepeatedSpecialChar("Title"),
  description: Yup.string().required("Please enter description").noLeadingSpace("Description").noBlankLines("Description")
  .noLeadingSpecialChar("Description")
  .noRepeatedSpecialChar("Description"),

});

export const AdminFeatureSchema = Yup.object().shape({
  header: Yup.string().required("Please enter title").noLeadingSpace("Title")
  .noLeadingSpecialChar("Title")
  .noRepeatedSpecialChar("Title"),
  description: Yup.string().required("Please enter description").noLeadingSpace("Description").noBlankLines("Description")
  .noLeadingSpecialChar("Description")
  .noRepeatedSpecialChar("Description"),
  icon: Yup.string().required("Please upload feature icon (only jpg, jpeg, and png files are allowed)"),
});



export const AdminRoadMapSchema = Yup.object().shape({
  title: Yup.string().required("Please enter title").noLeadingSpace("Title")
  .noLeadingSpecialChar("Title")
  .noRepeatedSpecialChar("Title"),
  description: Yup.string().required("Please enter description").noLeadingSpace("Description"),
  date: Yup.string().required("Please select date"),
});

export const AdminTokenomicsSchema = Yup.object().shape({
  text: Yup.string().required("Please enter title").noLeadingSpace("Title")
  .noLeadingSpecialChar("Title")
  .noRepeatedSpecialChar("Title"),
  amount: Yup.string().required("Please enter amount"),
  percentage: Yup.string().required("Please enter percentage").matches(/^[a-zA-Z]*(?:\d{1,3}(?:\.\d{1,2})?%)[a-zA-Z\s]*$/,"Invalit format"),
});



export const AdminAnalyticsSchema = Yup.object().shape({
  image: Yup.string().required("Please upload analytics image (only jpg, jpeg, and png files are allowed)"),
  description: Yup.string().required("Please enter description").noLeadingSpace("Description").noBlankLines("Description")
  .noLeadingSpecialChar("Description")
  .noRepeatedSpecialChar("Description"),
});


